<template>
  <form class="flex flex-col flex-auto justify-between" @submit.prevent="next">
    <mdl-textfield
      ref="username"
      class="block"
      :floatingLabel="trans('E-mail or mobile (incl. country code)')"
      :error="usernameError || errorMessage"
      @focus="errorMessage = null"
      v-model="username"
      v-autofocus
    />

    <template v-if="isSent">
      <p>{{ trans('passwords.sent') }}</p>
      <mdl-textfield
        ref="password"
        class="block"
        mono
        :floatingLabel="trans('Temporary password')"
        :error="passwordError"
        v-model="password"
      />

      <div class="action-bar">
        <mdl-button type="submit" :disabled="loading" raised primary>
          {{ trans('Log in') }}
        </mdl-button>

        <mdl-button @click="cancel">{{ trans('Back') }}</mdl-button>
      </div>
    </template>

    <template v-else>
      <div class="action-bar">
        <mdl-button type="submit" :disabled="loading || !username" raised primary>
          {{ trans('Send new password') }}
        </mdl-button>

        <mdl-button @click="cancel">{{ trans('Back') }}</mdl-button>
      </div>
    </template>
  </form>
</template>

<script>
export default {
  data() {
    return {
      errorMessage: null,
      isSent: false,
    }
  },

  computed: {
    loading() {
      return this.$store.state.loading
    },

    username: {
      get() {
        return this.$store.state.username
      },

      set(value) {
        this.$store.commit('updateUsername', value)
      },
    },

    usernameError() {
      return this.$store.state.usernameError
    },

    password: {
      get() {
        return this.$store.state.password
      },

      set(value) {
        this.$store.commit('updatePassword', value)
      },
    },

    passwordError() {
      return this.$store.state.errors.password
    },

    page() {
      return this.$store.state.page
    },

    verified() {
      return !!this.$store.state.captcha
    },
  },

  methods: {
    onCaptchaVerified(token) {
      this.$store.commit('captcha', token)
    },

    cancel() {
      this.password = null
      this.$store.commit('loading', false)
      this.$store.commit('navigate', 'login')
    },

    next() {
      if (this.isSent) this.login()
      else this.recover()
    },

    async recover() {
      this.isSent = false
      this.password = ''
      this.errorMessage = null
      this.$store.commit('loading', true)

      try {
        await Munio.api.auth.recover(this.username)
        this.isSent = true
        this.$nextTick(() => this.$refs.password.focus())
      } catch (err) {
        this.errorMessage = err.response.data.error.message
      }

      this.$store.commit('loading', false)
    },

    login() {
      return this.$store.dispatch('login')
    },
  },
}
</script>
